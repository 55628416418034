 /* global Component */
class badgesPage extends Component {

    static name() {
        return "badgesPage";
    }

    static componentName() {
        return "badgesPage";
    }

    updated() {
        return async function () {
            if(!this.isLogged){
                this.$store.dispatch('setRedirectLogin',"badges");
                this.$store.dispatch('activePreserveCar',true);
                this.$router.push("login");
            }
        };
    }


    getComputed() {
        return {
            viewIsLoading:function (){
                return !this.$store.getters.getItemsFullLoader;
            }
        };
    }

    getMethods() {
        return {
            loadCategories: this.loadCategories,
            selectCategory: this.selectCategory,
        };
    }


    loadCategories(root = null, lvl = 0) {
        this.$store.dispatch('loadCategories', root, lvl);
    }

    selectCategory(category) {
        if (category) {
            this.loadItems(category.code);
            //console.log("updateCategory select category",category)
            this.$router.push('/category/' + category.Name);
            this.$store.dispatch('updateCategory', category);
        }else {
            this.$store.dispatch('updateCategory', {});
            this.loadItems();
        }
    }

    getTemplate() {
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <NotificationComponent></NotificationComponent>
                  <div :class="classContainer">
                      <div class="section-main">
                          <div class="row">
                              <div class="col-12">
                                  <template v-if="viewIsLoading">
                                      <div class="loading">
                                          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                                      </div>
                                  </template>
                                  <template v-else>
                                      <CarComponentCompacted></CarComponentCompacted>
                                  </template>
                              </div>
                          </div>
                      </div>
                  </div>
                <footerSectionComponent></footerSectionComponent>
            </div>`;
    }
}

badgesPage.registerComponent();